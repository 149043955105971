.jumbotron {
  position: relative;
}
.jumbotron img {
  min-height: 400px;
  -o-object-fit: cover;
     object-fit: cover;
}
.jumbotron .description {
  padding: 0.5rem 1rem;
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
}/*# sourceMappingURL=ArticleHeader.css.map */