:root {
    
    
    --color-primary: #106DB7;
    --color-primary-hover: #207DC7;

    --footer-color: #A3A3A2;
    --footer-background: #191919;

    --border-color: #333;

    --navbar-background: white;
}

body {
    font-family: 'PT Sans', sans-serif;
}

a {
    text-decoration: none !important;
}

#root {
    min-height: 100vh;
}


.fs-8px {
    font-size: 8px;
}
.fs-10px {
    font-size: 10px;
}

.fs-12px {
    font-size: 12px;
}

.fs-20px {
    font-size: 20px;
}

.fs-25px {
    font-size: 25px;
}

.btn.btn-xs {
    font-size: 10px;
}

.vscomp-ele-parent-w100 .vscomp-ele {
    max-width: unset;
}

.modal .modal-footer {
    justify-content: flex-start;
}


.container.container-500px {
    max-width: 500px;
}