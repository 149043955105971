a {
  cursor: pointer;
}

[type=button]:disabled {
  pointer-events: visible;
  cursor: not-allowed;
}

.text-justify {
  text-align: justify;
}

.text-primary {
  color: var(--color-primary) !important;
}

.bg-primary {
  background: var(--color-primary) !important;
}

.btn-primary,
.btn-primary:disabled {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
  color: #fff;
}
.btn-primary:hover,
.btn-primary:disabled:hover {
  background-color: var(--color-primary-hover);
  border-color: var(--color-primary);
}

.nav-link {
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  color: black;
}

.bg-orange-700 {
  background: #f68f55;
  color: white;
}

.btn-primary:disabled {
  opacity: 0.2;
}

.btn-outline-primary,
.btn-outline-primary:disabled {
  background-color: #fff;
  border-color: var(--color-primary);
  color: var(--color-primary);
}
.btn-outline-primary:hover,
.btn-outline-primary:disabled:hover {
  background-color: var(--color-primary-hover);
  border-color: var(--color-primary);
}

.card-media {
  position: relative;
  overflow: hidden;
  text-decoration: none;
  border-radius: 0.5rem;
  display: block;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.card-media::after {
  content: "";
  position: absolute;
  inset: 0 0 0 0;
  pointer-events: none;
}
.card-media:hover::after {
  background: rgba(220, 220, 220, 0.1);
}
.card-media img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.card-media .unavailable {
  position: absolute;
  text-align: center;
  color: var(--bs-danger);
  font-weight: 600;
  left: 0;
  right: 0;
  top: 5px;
  z-index: 1;
  padding: 5px 16px;
}
.card-media .card-media-content {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 0;
}
.card-media .card-media-content .card-media-bottom h2,
.card-media .card-media-content .card-media-bottom h3 {
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 2rem;
  color: white;
  margin: 0;
  padding: 0 20px 5px;
  color: black;
}
.card-media .card-media-content .card-media-bottom p {
  font-size: 1.05rem;
  font-weight: 500;
  margin: 0;
  padding: 0 20px 10px;
  color: var(--bs-secondary);
}

.form-group label {
  font-size: 0.675rem;
  color: #595959;
  letter-spacing: 0.5px;
}

.form-control[readonly] {
  background-color: #e9ecef;
}

.form-floating label,
.form-floating label::after {
  background-color: transparent !important;
}

input.form-control,
select.form-select {
  font-size: 0.8rem;
}

.vscomp-toggle-button {
  font-size: 0.8rem;
  font-weight: 400;
  border-radius: 5px;
}

.vscomp-ele-parent-w100 .vscomp-wrapper .checkbox-icon.checked::after,
.vscomp-ele-parent-w100 .vscomp-wrapper.multiple .vscomp-option.selected .checkbox-icon::after {
  border-color: var(--color-primary);
  border-left-color: rgba(0, 0, 0, 0);
  border-top-color: rgba(0, 0, 0, 0);
}
.vscomp-ele-parent-w100 .vscomp-wrapper:not(.has-value) .vscomp-value {
  opacity: 0.75;
}

.toast .toast-header {
  background-color: inherit;
  color: inherit;
}
.toast.bg-danger {
  color: white !important;
}

.fancy-button {
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.fancy-button .name {
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1;
}
.fancy-button .description {
  font-size: 0.9rem;
  font-weight: 300;
  line-height: 1.2;
}
@media (max-width: 575px) {
  .fancy-button .description {
    font-size: 11px;
    font-weight: 400;
  }
}

.h-fancy {
  border-bottom: 10px solid var(--color-primary);
  font-weight: 600;
  text-align: center;
  margin: 0.5rem 0;
}

.virtual-table-stylish {
  font-size: 0.9rem;
  line-height: 1;
}
@media (max-width: 575px) {
  .virtual-table-stylish {
    font-size: 0.75rem;
  }
}
.virtual-table-stylish p {
  font-size: inherit;
  margin: 0 0 0.25rem;
  line-height: inherit;
  text-align: inherit;
}
.virtual-table-stylish .row {
  margin: 0 -2px;
}
.virtual-table-stylish .row [class*=col] {
  background: #F4F6F4;
  padding: 3px 5px;
  border: 2px solid white;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.virtual-table-stylish .row .col-w60 {
  width: 100px;
}
.virtual-table-stylish .row .col-w80 {
  width: 140px;
}
@media (max-width: 575px) {
  .virtual-table-stylish .row .col-w60 {
    width: 60px;
  }
  .virtual-table-stylish .row .col-w80 {
    width: 80px;
  }
}

ul li,
ol li {
  text-align: justify;
}

.form-control-colored {
  background: #E3F2FD;
}

@media (min-width: 1200px) {
  .px-xl-12px {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
}/*# sourceMappingURL=App.css.map */