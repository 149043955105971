.gallery {
  z-index: 1050;
  position: fixed;
  inset: 20px 0 20px 0;
}
.gallery img {
  max-height: 150px;
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}
.gallery .gallery-backdrop {
  opacity: 0.6;
  content: " ";
  position: fixed;
  inset: 0 0 0 0;
  z-index: 1050;
  background-color: #000;
}
.gallery .gallery-inner {
  z-index: 1051;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 800px;
  max-width: calc(100% - 40px);
}
.gallery .gallery-inner img {
  margin: 0 auto;
  max-width: 100%;
  max-height: calc(100vh - 40px);
}
.gallery .gallery-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 100002147483651;
  background: black;
  color: white;
  width: 30px;
  height: 30px;
  border: none;
  border-radius: 25px;
  transform: translate(50%, -50%);
}/*# sourceMappingURL=CarouselGallery.css.map */