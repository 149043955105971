footer {
    background: var(--footer-background);

    a,
    p,
    ul {
        color: var(--footer-color);
    }

    p {
        font-size: 14px;
    }
    .contact-info {
        line-height: 1.4286em;
        list-style: none;
        padding: 0;

        li {
            font-size: 14px;
            margin-top: 6px;
            border-radius: 4px;
            padding: 7px 12px 7px 44px;
            position: relative;

            &:before {
                border-right: 1px solid rgba(52, 52, 52, 0.8);
                bottom: 1px;
                content: "";
                height: 100%;
                left: 0;
                position: absolute;
                top: 0;
                width: 31px;
            }

            i {
                left: 0;
                position: absolute;
                text-align: center;
                top: 50%;
                -ms-transform: translateY(-50%);
                transform: translateY(-50%);
                width: 31px;
                color: var(--color-primary);
            }
        }
    }

    .menu-widget {
        ul {
            list-style: none;
            padding: 0;
            li {
                margin-bottom: 17px;
                display: inline-block;
                float: none;
                width: calc(50% - 2px);

                a {
                    padding-left: 14px;
                    position: relative;
                    &:before {
                        content: ">";
                        font-size: 14px;
                        font-style: italic;
                        left: 0;
                        position: absolute;
                    }
                }
            }
        }
    }

    .menu-inline {
        ul {
            list-style: none;
            padding: 0;
            li {
                display: inline;
                padding-right: 1rem;

                i {
                    font-size: 1.5rem;
                }
            }
        }
    }

    .working-hours {
        border-collapse: separate;
        border-spacing: 0 6px;
        width: 100%;
        border: 2px solid var(--footer-color);
        padding: 10px 0;

        th,
        td {
            border: none;
            padding: 0 25px;
            border-right-style: none;
            font-weight: 400;
        }

        th {
            color: var(--footer-color);
        }

        td {
            color: white;
        }
    }

    .footer-copyright {
        border-top: 1px solid var(--border-color);
        padding-top: 2rem;
        margin-top: 2rem;
        p {
            padding: 0;
            margin: 0;
        }
    }
}
