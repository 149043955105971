footer {
  background: var(--footer-background);
}
footer a,
footer p,
footer ul {
  color: var(--footer-color);
}
footer p {
  font-size: 14px;
}
footer .contact-info {
  line-height: 1.4286em;
  list-style: none;
  padding: 0;
}
footer .contact-info li {
  font-size: 14px;
  margin-top: 6px;
  border-radius: 4px;
  padding: 7px 12px 7px 44px;
  position: relative;
}
footer .contact-info li:before {
  border-right: 1px solid rgba(52, 52, 52, 0.8);
  bottom: 1px;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 31px;
}
footer .contact-info li i {
  left: 0;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  width: 31px;
  color: var(--color-primary);
}
footer .menu-widget ul {
  list-style: none;
  padding: 0;
}
footer .menu-widget ul li {
  margin-bottom: 17px;
  display: inline-block;
  float: none;
  width: calc(50% - 2px);
}
footer .menu-widget ul li a {
  padding-left: 14px;
  position: relative;
}
footer .menu-widget ul li a:before {
  content: ">";
  font-size: 14px;
  font-style: italic;
  left: 0;
  position: absolute;
}
footer .menu-inline ul {
  list-style: none;
  padding: 0;
}
footer .menu-inline ul li {
  display: inline;
  padding-right: 1rem;
}
footer .menu-inline ul li i {
  font-size: 1.5rem;
}
footer .working-hours {
  border-collapse: separate;
  border-spacing: 0 6px;
  width: 100%;
  border: 2px solid var(--footer-color);
  padding: 10px 0;
}
footer .working-hours th,
footer .working-hours td {
  border: none;
  padding: 0 25px;
  border-right-style: none;
  font-weight: 400;
}
footer .working-hours th {
  color: var(--footer-color);
}
footer .working-hours td {
  color: white;
}
footer .footer-copyright {
  border-top: 1px solid var(--border-color);
  padding-top: 2rem;
  margin-top: 2rem;
}
footer .footer-copyright p {
  padding: 0;
  margin: 0;
}/*# sourceMappingURL=LayoutFooter.css.map */