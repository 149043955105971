.jumbotron {
    position: relative;


    img {
        min-height: 400px;
        object-fit: cover;
    }
    .description {
        padding: .5rem 1rem;
        position: absolute;
        top: 20%;
        left: 50%;
        transform: translateX(-50%);
        background: rgba(0, 0, 0, 0.5);
        color: white;
    }
}
