

.gallery {
    z-index: 1050;
    position: fixed;
    inset: 20px 0 20px 0;

    
    img {
        max-height: 150px;
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center;
    }

    .gallery-backdrop {
        opacity: .6;
        content: " ";
        position: fixed;
        inset: 0 0 0 0;
        z-index: 1050;
        background-color: #000;
    }

    .gallery-inner {
        z-index: 1051;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 800px;
        max-width: calc(100% - 40px);


        img {
            margin: 0 auto;
            max-width: 100%;
            max-height: calc(100vh - 40px);
        }
    }



    .gallery-close {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 100002147483651;
        background: black;
        color: white;
        width: 30px;
        height: 30px;
        border: none;
        border-radius: 25px;
        transform: translate(50%, -50%);
    }
}
