.calendar {
    &.calendar-primary {
        .calendar-month {
            border: 1px solid var(--color-primary) !important;
        }

        .calendar-header, .day-of-week {
            background: var(--color-primary) !important;
            color: white !important;
        }

        .disabled {
            background: var(--bs-gray-200) !important;
            opacity: unset !important;

            .name {
                color: #aaa !important;
            }
        }
    }

    .bg-available {
        background: #BFFFBF;
    }

    .bg-unavailable {
        background: #FFBFBF;
        background: repeating-linear-gradient( -45deg, #FFBFBF, #FFBFBF 10px, #FF9F9F 10px, #FF9F9F 20px );
    }

    .bg-partially-available {
        background: #FFFCBF;
    }

    .month-name {
        font-size: 1.25rem;
        font-weight: bold;
        text-transform: uppercase;
        margin: 10px 0 5px;
        display: flex;
        justify-content: center;

        a {
            color: var(--bs-gray-300);
            margin: 0 1rem;

            &.disabled {
                color: var(--bs-gray-200);
                cursor: not-allowed;
            }
        }
    }

    .calendar-month {
        border: 1px solid var(--bs-gray-500);
        width: 100%;

        .calendar-row {
            display: flex;

            .day {
                text-align: center;
                flex: 1;
                border: 1px solid white;

                &.day-of-week {
                    background: var(--bs-gray-200);
                    font-size: .75rem;
                    padding: .25rem 0;
                    text-transform: uppercase;
                    border-bottom: 1px solid var(--bs-gray-500);
                }

                .name {
                    color: black;
                    padding: 0 .5rem;
                    margin: 0 auto;
                }

                &.gray {
                    background: var(--bs-gray-200);
                }

                &.disabled {
                    cursor: not-allowed;
                    background: var(--bs-gray-100);
                    opacity: .2;
                }

                &.active {
                    .name {
                        background: var(--color-primary);
                        color: white;
                    }
                }
            }
        }
    }

    .calendar-legend {
        margin: .5rem 0 0;

        .legend-color {
            width: 20px;
            height: 20px;
        }

        .legend-name {
            margin: 0 .25rem;
            font-size: .8rem;
        }
    }
}
