.title-widget {
  margin-bottom: 22px;
  border-color: #2e2e2e;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  margin: 0 0 24px;
  padding-bottom: 16px;
  position: relative;
}
.title-widget:after {
  background-color: var(--color-primary);
  bottom: -1px;
  content: "";
  height: 3px;
  left: 0;
  position: absolute;
  width: 73px;
}/*# sourceMappingURL=TitleWidget.css.map */