.calendar.calendar-primary .calendar-month {
  border: 1px solid var(--color-primary) !important;
}
.calendar.calendar-primary .calendar-header, .calendar.calendar-primary .day-of-week {
  background: var(--color-primary) !important;
  color: white !important;
}
.calendar.calendar-primary .disabled {
  background: var(--bs-gray-200) !important;
  opacity: unset !important;
}
.calendar.calendar-primary .disabled .name {
  color: #aaa !important;
}
.calendar .bg-available {
  background: #BFFFBF;
}
.calendar .bg-unavailable {
  background: #FFBFBF;
  background: repeating-linear-gradient(-45deg, #FFBFBF, #FFBFBF 10px, #FF9F9F 10px, #FF9F9F 20px);
}
.calendar .bg-partially-available {
  background: #FFFCBF;
}
.calendar .month-name {
  font-size: 1.25rem;
  font-weight: bold;
  text-transform: uppercase;
  margin: 10px 0 5px;
  display: flex;
  justify-content: center;
}
.calendar .month-name a {
  color: var(--bs-gray-300);
  margin: 0 1rem;
}
.calendar .month-name a.disabled {
  color: var(--bs-gray-200);
  cursor: not-allowed;
}
.calendar .calendar-month {
  border: 1px solid var(--bs-gray-500);
  width: 100%;
}
.calendar .calendar-month .calendar-row {
  display: flex;
}
.calendar .calendar-month .calendar-row .day {
  text-align: center;
  flex: 1;
  border: 1px solid white;
}
.calendar .calendar-month .calendar-row .day.day-of-week {
  background: var(--bs-gray-200);
  font-size: 0.75rem;
  padding: 0.25rem 0;
  text-transform: uppercase;
  border-bottom: 1px solid var(--bs-gray-500);
}
.calendar .calendar-month .calendar-row .day .name {
  color: black;
  padding: 0 0.5rem;
  margin: 0 auto;
}
.calendar .calendar-month .calendar-row .day.gray {
  background: var(--bs-gray-200);
}
.calendar .calendar-month .calendar-row .day.disabled {
  cursor: not-allowed;
  background: var(--bs-gray-100);
  opacity: 0.2;
}
.calendar .calendar-month .calendar-row .day.active .name {
  background: var(--color-primary);
  color: white;
}
.calendar .calendar-legend {
  margin: 0.5rem 0 0;
}
.calendar .calendar-legend .legend-color {
  width: 20px;
  height: 20px;
}
.calendar .calendar-legend .legend-name {
  margin: 0 0.25rem;
  font-size: 0.8rem;
}/*# sourceMappingURL=calendar-widget.css.map */